<template>
  <div class="index-page">
    <div class="content">
      <div class="content_item">
        <div class="title">
          <div>
            <img src="@/assets/index_img/99yytitle.png" alt="" />
          </div>
          <div class="title_center">
            <img src="@/assets/index_img/99yycontent.png" alt="" />
          </div>
          <div class="title_btn">
            <div
              class="btn"
              @click="lookmore()"
              :class="{ btns: more == true }"
            >
              了解详情+
            </div>
            <!-- <img src="@/assets/index_img/huibtn.png" alt="" /> -->
          </div>
        </div>
        <div>
          <img src="@/assets/index_img/99yylogo.png" alt="" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_item">
        <div>
          <img src="@/assets/index_img/99sylogo.png" alt="" />
        </div>
        <div class="title">
          <div>
            <img src="@/assets/index_img/99sytitle.png" alt="" />
          </div>
          <div class="title_center">
            <img src="@/assets/index_img/99sycontent.png" alt="" />
          </div>
          <div class="title_btn">
            <div
              class="btn"
              @click="lookmore2()"
              :class="{ btns: more2 == true }"
            >
              了解详情+
            </div>
            <!-- <img src="@/assets/index_img/bluebtn.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_item">
        <div class="title">
          <div>
            <img src="@/assets/index_img/99cstitle.png" alt="" />
          </div>
          <div class="title_center">
            <img src="@/assets/index_img/99cscontent.png" alt="" />
          </div>
          <div class="title_btn">
            <div
              class="btn"
              @click="lookmore3()"
              :class="{ btns: more3 == true }"
            >
              了解详情+
            </div>
            <!-- <img src="@/assets/index_img/huibtn.png" alt="" /> -->
          </div>
        </div>
        <div>
          <img src="@/assets/index_img/99cslogo.png" alt="" />
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_item">
        <div>
          <img src="@/assets/index_img/99lglogo.png" alt="" />
        </div>
        <div class="title">
          <div>
            <img src="@/assets/index_img/99lgtitle.png" alt="" />
          </div>
          <div class="title_center">
            <img src="@/assets/index_img/99lgcontent.png" alt="" />
          </div>
          <div class="title_btn">
            <div
              class="btn"
              @click="lookmore4()"
              :class="{ btns: more4 == true }"
            >
              了解详情+
            </div>
            <!-- <img src="@/assets/index_img/huibtn.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="content relation">
      <h4>联系我们，快速获得并体验产品及服务</h4>
      <p class="relation-detail">
        针对行业定制化服务，帮助企业合规财务，降本增收。
      </p>
      <div class="ul">
        <div v-for="(item, index) in userlist" :key="index">
          <img :src="item.src" alt="" />
          <p class="relation-tit">{{ item.title }}</p>
          <span>{{ item.content }}</span>
          <a
             target="_blank"
            href="https://affim.baidu.com/unique_24946238/chat?siteId=19117220&userId=24946238&siteToken=889ff473bd58d7a3343e6d56540df50d&cp=www.99qibang.cn&cr=99qibang.cn&cw=seo&p=99qibang.com"
            
            >立即定制</a
          >
        </div>
      </div>
    </div>
    <div class="content" style="margin-bottom: 183px">
      <div class="content_title">
        <img src="@/assets/img/comentFriend.png" alt="" />
      </div>
      <div class="friendContent">
        <img src="@/assets/index_img/friend1.png" alt="" />
        <img src="@/assets/index_img/friend2.png" alt="" />
        <img src="@/assets/index_img/friend3.png" alt="" />
        <img src="@/assets/index_img/friend4.png" alt="" />
        <img src="@/assets/index_img/friend5.png" alt="" />
        <img src="@/assets/index_img/friend6.png" alt="" />
        <img src="@/assets/index_img/friend7.png" alt="" />
        <img src="@/assets/index_img/friend8.png" alt="" />
        <img src="@/assets/index_img/friend9.png" alt="" />
        <img src="@/assets/index_img/friend10.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

// import banner from "@/components/banner.js";

export default {
  name: "IndexPage",
  data() {
    return {
      more: false, // 查看更多按钮的变化
      more2: false, // 查看更多按钮的变化
      more3: false, // 查看更多按钮的变化
      more4: false, // 查看更多按钮的变化
      userlist: [
        {
          src: require("@/assets/index_img/relation1.png"),
          title: "方案定制",
          content:
            "用我们的专业为您提供适用于各行业的全套定制方案，提升您的企业运营效率",
        },
        {
          src: require("@/assets/index_img/relation2.png"),
          title: "了解政策",
          content:
            "实时掌握一手地方税收优惠政策，根据国家法律要求，及专业服务团队，保证落地安全稳定",
        },
        {
          src: require("@/assets/index_img/relation3.png"),
          title: "预约参观",
          content:
            "无论您代表企业还是个人，99企帮都热情的期待您的莅临，实地参观深入沟通",
        },
      ],
    };
  },
  created() {},
  methods: {
    // 99优园点击了解详情
    lookmore() {
      this.more = true;
      location.href = "/view/99youyuan";
      // this.$router.push({
      //   path: "/view/99youyuan",
      // });
    },
    // 99税优点击了解详情
    lookmore2() {
      this.more2 = true;
      location.href = "/view/99shuiyou";
      // this.$router.push({
      //   path: "/view/99shuiyou",
      // });
    },
    // 99财税点击了解详情
    lookmore3() {
      this.more3 = true;
      location.href = "/view/99caishui";
      // this.$router.push({
      //   path: "/view/99caishui",
      // });
    },
    // 99灵工了解详情
    lookmore4() {
      this.more4 = true;
      location.href = "/view/99linggong";
      // this.$router.push({
      //   path: "/view/99linggong",
      // });
    },
  },
};
</script>
<style scoped>
.content {
  padding-top: 200px;
  margin: 0 auto;
}
.content_item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_title {
  text-align: center;
  margin-bottom: 73px;
}
.title {
  text-align: center;
  width: 600px;
}
.title_center {
  font-size: 20px;
  color: #999999;
  padding: 28px 0px 60px 0px;
}
.title_btn {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.btn {
  text-align: center;
  width: 230px;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  background-color: #5b5e66;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.btn:hover {
  background-color: #3d93f9;
}
.btns {
  background-color: #3d93f9;
}
.friendContent {
  width: 1273px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.relation {
  width: 100%;
  background-color: #f7f9fa;
  text-align: center;
}
.relation h4 {
  font-size: 28px;
}
.relation-detail {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 2px;
  color: #333333;
  margin: 17px 0 59px;
}
.relation .ul {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}
.relation a {
  width: 130px;
  height: 40px;
  line-height: 37px;
  border-radius: 2px;
  border: solid 1px #3d93f9;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  letter-spacing: 2px;
  color: #3d93f9;
  display: block;
  margin-left: 76px;
  margin-top: 38px;
}
a:hover {
  color: #ffffff;
  background-color: #3d93f9;
}
.relation span {
  width: 285px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #333333;
  display: block;
}
.relation-tit {
  font-family: MicrosoftYaHei;
  font-size: 24px;
  letter-spacing: 1px;
  color: #333333;
  margin: 34px 0 28px;
}
.relation .ul img {
  width: 280px;
  height: auto;
}
</style>
